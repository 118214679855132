import { dayjs } from '/machinery/dayjs'
import { toPlainText } from '@portabletext/react'
import { asRouteMap } from '@kaliber/routing'
import groq from 'groq'
import { createGroqSnippets } from './createGroqSnippets'
import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'

const { extract, language, slug } = parameterExtraction

const snippets = createGroqSnippets()
const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',

        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        },

        search: {
          path: 'search',
          data: requestHandlers => requestHandlers.search,
        },

        jobWithoutTitleRedirect: {
          path: '/to-job/:jobId',
          data: requestHandlers => requestHandlers.jobWithoutTitleRedirect,
        },

        linkedin: {
          path: 'linkedin',
          data: requestHandlers => requestHandlers.linkedin,
        },

        indeed: {
          path: 'indeed',
          data: requestHandlers => requestHandlers.indeed,
        },
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,

      home: {
        path: '',
        data: routeData.app.home.data,
      },
      jobs: {
        path: 'jobs',
        index: {
          path: '',
          data: routeData.app.jobs.data
        },
        detail: {
          path: ':jobTitle/:jobId',
          data: routeData.app.jobs.detail.data,

          thankYou: {
            path: 'thank-you',
            data: routeData.app.jobs.detail.thankYou.data,
          }
        },
      },
      page: {
        path: ':slug',
        data: routeData.app.page.data,
      },
      notFound: '*',
    },
    admin: 'admin',
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          menu: [
            groq`*[_type == 'menu' && language == $language] | order(_updatedAt desc) [0] {
              items[] {
                title,
                link->${snippets.linkableDocumentObject},
              },
              mobileMenuBackgroundImage,
              graph {
                employees,
                growth
              }
            }`,
            { language }
          ],
          settings: groq`*[_id == 'settings'] | order(_updatedAt desc) [0] {
            shareImage,
            organisatienaam
          }`,
        }),
        extractParams: { app: extract(language) },
        derived: ({ data, params }) => ({
          dataLayer: metadata({
            content: {
              language: params.language,
              ...(data.doc?._id && { id: data.doc._id }),
              ...(data.doc?.title && { title: data.doc.title }),
            }
          })
        }),
      },

      home: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'home' && language == $language] | order(_updatedAt desc) [0] {
                _id,
                title,
                imageLg,
                imageSm,
                intro,
                button ${snippets.referenceWithLabelObject},
                image,
                whoWeAre {
                  title,
                  button ${snippets.referenceWithLabelObject},
                },
                meetTheTeam ${snippets.textWithImagesObject},
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ],
          }),
          fetch: {
            jobsCount: requestHandlers => requestHandlers.jobsCount,
            latestJobs: requestHandlers => requestHandlers.latestJobs,
          },
          derived: ({ data, derived }) => data.doc && ({
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              description: data.doc.intro,
              social: {
                shareImage: data.doc.image || data.settings?.shareImage
              }
            }),
            dataLayer: metadata({
              content: {
                ...derived.dataLayer.metadata.content,
                type: 'home',
              }
            })
          }),
          extractParams: { home: extract(language) }
        }
      },

      jobs: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'jobs' && language == $language] | order(_updatedAt desc) [0] {
                _id,
                title,
                image,
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ],
          }),
          fetch: {
            jobs: requestHandlers => requestHandlers.jobs,
            filters: requestHandlers => requestHandlers.filters,
          },
          derived: ({ data, derived }) => data.doc && {
            doc: docWithSeo(data.doc, {
              title: 'Our job openings',
              description: 'Join the team at Bitvavo en build your crypto career with us. Work to bridge the gap between traditional currencies and digital assets. Located in Amsterdam',
              social: {
                shareImage: data.doc.image || data.settings?.shareImage
              }
            }),
            dataLayer: metadata({
              content: {
                ...derived.dataLayer.metadata.content,
                type: 'jobs-overview',
              }
            })
          },
          extractParams: { jobs: extract(language) }
        },

        detail: {
          data: {
            fetch: {
              jobData: requestHandlers => requestHandlers.job,
            },
            derived: ({ data, derived }) => data.jobData?.job && derivedJob({ data, derived })
          },

          thankYou: {
            data: {
              groq: ({ params: { language } }) => ({
                doc: [
                  groq`*[_type == 'thankYou' && language == $language] | order(_updatedAt desc) [0] {
                    _id,
                    content[] ${snippets.contentObject},
                    seo,
                    'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                  }`,
                  { language }
                ]
              }),
              derived: ({ data }) => {
                return {
                  ...data,
                  dataLayer: metadata({
                    content: {
                      type: 'thank-you',
                      title: 'Thank you for applying',
                    }
                  })
                }
              },
            },
          },
        },
      },

      page: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'page' && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                _id,
                _updatedAt,
                _createdAt,
                title,
                image,
                introduction,
                buttons[] { title, link->${snippets.linkableDocumentObject} },
                content[] ${snippets.contentObject},
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language, slug }
            ],
          }),
          extractParams: { page: extract(language, slug) },
          derived: ({ data, derived }) => data.doc && {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              description: data.doc.introduction,
              social: {
                shareImage: data.doc.image || data.doc.hero
              }
            }),
            dataLayer: metadata({
              content: {
                ...derived.dataLayer.metadata.content,
                type: 'content-page',
                ...(data.doc?._createdAt && { datecreated: dayjs(data.doc._createdAt).utc().format('YYYY-MM-DD') }),
                ...(data.doc?._updatedAt && { dateupdated: dayjs(data.doc._updatedAt).utc().format('YYYY-MM-DD') }),
                wordcount: toPlainText(data.doc.content ?? []).length,
              }
            })
          },
        },
      },
    }
  }
}

function derivedJob({ data, derived }) {
  const job = data.jobData.job ?? {}
  const canonicalUrl = data.jobData.canonicalUrl

  return {
    doc: {
      ...data.jobData,
      seo: {
        title: job.title,
        description: job.content,
        social: {
          shareImage: data.jobData.jobAdditionalData?.department?.image || data.settings?.shareImage
        },
        ...(canonicalUrl && { advanced: { canonicalUrl } }),
      }
    },
    dataLayer: metadata({
      job: {
        id: job.id,
        title: job.title,
        ...(job.employmentType && { contracttype: job.employmentType }),
        publishdate: dayjs(job.publishedDate).format('YYYY-MM-DD'),
        dateupdated: dayjs(job.updatedDate).format('YYYY-MM-DD'),
        datecreated: dayjs(job.createdDate).format('YYYY-MM-DD'),
        location: getLocationMetadata(job.offices),
        country: 'nl',
        department: {
          id: job.department?.id,
          name: job.department?.name
        }
      },
      content: {
        ...derived.dataLayer.metadata.content,
        id: job.id,
        title: job.title,
        type: 'job',
      }
    }),
    structuredData: {
      type: 'JobPosting',
      data: {
        publishedDate: dayjs(job.publishedDate).format('YYYY-MM-DD'),
        employmentType: job.employmentType,
        description: job.content,
        logo: '/static/favicon/android-chrome-512x512.png',
        city: getCityName(job.offices),
        country: 'NL',
        title: job.title,
        id: job.id,
      }
    },
  }
}

function metadata(data) {
  return { metadata: data }
}

function getCityName(offices) {
  const [firstOffice] = offices ?? []
  return firstOffice?.name
}

function getLocationMetadata(offices) {
  const [firstOffice] = offices ?? []

  return {
    city: firstOffice?.name,
    id: firstOffice?.id,
  }
}
