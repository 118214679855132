import groq from 'groq'

export function createGroqSnippets() {
  const linkableDocumentFields = groq`_type, slug, language, _id` // TODO: BIT-284, job detail multi-language
  const linkableDocumentObject = groq`{${linkableDocumentFields}}`
  const referenceWithLabelObject = groq`{label, ref->${linkableDocumentObject}}`
  const linkableDocumentObjectWithTitle = groq`{ title, ${linkableDocumentFields} }`
  const internalLinkObject = groq`{ref->${linkableDocumentObject}}`

  const textWithImagesObject = groq`{
    title,
    description,
    button ${referenceWithLabelObject},
    images[],
  }`

  const benefitsObject = groq`{
    benefits[]-> { title,iconImage,alt }
  }`

  const testimonialsObject = groq`{
    color,
    testimonials[] {
      _key,
      _type == 'reference' => @-> {quote, author, role, image, color},
    }
  }`

  const contentObject = groq`{
    ..., // We need all structured content here
    _type == 'benefits' => ${benefitsObject},
    _type == 'testimonials' => ${testimonialsObject},
    _type == 'imagesWithText' => ${textWithImagesObject},
    markDefs[] {
      ...,
      _type == 'internalLink' => ${internalLinkObject}
    }
  }`

  return {
    linkableDocumentObjectWithTitle,
    linkableDocumentObject,
    internalLinkObject,
    contentObject,
    textWithImagesObject,
    referenceWithLabelObject,
    translations(projection) {
      return groq`
        *[
          _id != ^._id &&
          _type == ^._type &&
          translationId == ^.translationId
        ]
        ${projection}
      `
    },
  }
}
